import request from '@/utils/request'

// 查询检测规则
export const searchTestItems: any = (data: any) => {
    return request({
        url: '/item/search',
        method: 'post',
        data
    })
}

export const addTestItem: any = (data: any) => {
    return request({
        url: '/item',
        method: 'post',
        data
    })
}

export const updateTestItems: any = (data: any) => {
    return request({
        url: '/item',
        method: 'put',
        data
    })
}

export const getTestItemInfo: any = (id: number) => {
    return request({
        url:`/item/${id}`,
        method: 'get',
    })
}

// 查询监护规则
export const searchMonitorItems: any = (data: any) => {
    return request({
        url: '/item/list',
        method: 'post',
        data
    })
}